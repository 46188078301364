<!--
File: Report_1_AdministrativeSetup.vue
Description: produce the list of DEUs, and Regions under RMD for printing.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <RegionsDropdown class='region-select-dropdown' :label="$t('label.select_region')" v-model="selected_region"
            @input='onRegionChange' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-datepicker v-model="selected_date" md-immediately :md-open-on-focus="false">
            <label>{{ $t('label.select_date') }}</label>
          </md-datepicker>
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="print" :disabled="btnDisabled"> {{ $t('buttons.print') }}
          </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <md-progress-spinner v-if="showSpinner == true" class="md-progress-spinner" :md-diameter="70"
        md-mode="indeterminate" />
      <md-table class='"paginated-table table-striped table-hover' :value="report" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell style="width:20%" :md-label="$t('road_network.region')" md-sort-by="region_description">
            {{ item.region_description }}
          </md-table-cell>
          <md-table-cell style="width:10%" :md-label="$t('road_network.dep')" md-sort-by="deu_description">
            <a href='#' @click.stop.prevent='showSections(item)'>
              {{ $t('road_network.dep') + '-' + item.deu_description }}
            </a>
          </md-table-cell>
          <md-table-cell style="width:20%" :md-label="$t('road_network.length_km_road_class_em')"
            md-sort-by="length_km_road_class_em" md-numeric>
            {{ item.length_km_road_class_em | numFormat }}
          </md-table-cell>
          <md-table-cell style="width:20%" :md-label="$t('road_network.length_km_road_class_m')"
            md-sort-by="length_km_road_class_m" md-numeric>
            {{ item.length_km_road_class_m | numFormat }}
          </md-table-cell>
          <md-table-cell style="width:15%" :md-label="$t('road_network.length_km_road_class_zh')"
            md-sort-by="length_km_road_class_zh" md-numeric>
            {{ item.length_km_road_class_zh | numFormat }}
          </md-table-cell>
          <md-table-cell style="width:15%" :md-label="$t('road_network.road_class_none')" md-sort-by="road_class_none"
            md-numeric>
            {{ item.road_class_none | numFormat }}
          </md-table-cell>
        </md-table-row>
      </md-table>
      <md-table id="printFooter">
        <md-table-row>
          <md-table-head style="width:20%">{{ $t('label.total') }}</md-table-head>
          <md-table-head style="width:10%">{{ total_deu }}</md-table-head>
          <md-table-head style="width:20%" md-numeric>{{ total_length_km_road_class_em }}</md-table-head>
          <md-table-head style="width:20%" md-numeric>{{ total_length_km_road_class_m }}</md-table-head>
          <md-table-head style="width:15%" md-numeric>{{ total_length_km_road_class_zh }}</md-table-head>
          <md-table-head style="width:15%" md-numeric>{{ total_road_class_none }}</md-table-head>
        </md-table-row>
      </md-table>
    </md-card-content>
  </md-card>
</template>
<script>
  import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
  import { numFormat } from "@/store/refdata/format_helpers"
  import { customSortMixin } from '@/customSortMixin'

  export default {
    name: 'roads-administrative-setup',
    mixins: [customSortMixin],
    data() {
      return {
        selected_region: null,
        selected_date: new Date(),
        btnDisabled: true,
        region_desc: null,

        showSpinner: false,
        currentSort: 'region_description',
        currentSortOrder: 'asc'
      }
    },
    components: {
      RegionsDropdown
    },
    mounted() {
      this.showSpinner = true
    },

    methods: {
      reloadData() {
        this.showSpinner = true
        this.btnDisabled = true
        this.$store.dispatch('REPORT_1_ADMINISTRATIVE_SETUP', {
          inserted_date: this.selected_date.toISOString().substring(0, 10),
          region_id: this.selected_region
        }).then(() => {
          this.btnDisabled = false
          this.showSpinner = false
        })
      },
      print() {
        this.showSpinner = true
        const tableHeaders = `<tr>
          <th>${this.$t('road_network.region')}</th>
                <th>${this.$t('road_network.dep')}</th>
                <th>${this.$t('road_network.length_km_road_class_em')}</th>
                <th>${this.$t('road_network.length_km_road_class_m')}</th>
                <th>${this.$t('road_network.length_km_road_class_zh')}</th>
                <th>${this.$t('road_network.road_class_none')}</th>
        </tr>`

        let tableRows = ''
        this.report.forEach(item => {
          tableRows += `<tr>
            <td>${item.region_description}</td>
                  <td>${this.$t('road_network.dep') + '-' + item.deu_description}</td>
                  <td class='numCell'>${numFormat(item.length_km_road_class_em)}</td>
                  <td class='numCell'>${numFormat(item.length_km_road_class_m)}</td>
                  <td class='numCell'>${numFormat(item.length_km_road_class_zh)}</td>
                  <td class='numCell'>${numFormat(item.road_class_none)}</td>
          </tr>`
        })

        tableRows += `
      <tr>
        <th>${this.$t('label.total')}</th>
              <th>${this.total_deu}</th>
              <th class='numCell'>${this.total_length_km_road_class_em}</th>
              <th class='numCell'>${this.total_length_km_road_class_m}</th>
              <th class='numCell'>${this.total_length_km_road_class_zh}</th>
              <th class='numCell'>${this.total_road_class_none}</th>
      </tr>`

        const prtHtml = `<!DOCTYPE html>
    <html>
      <head>
        <style>
          body { font-family: Arial, sans-serif; }
          table { width: 100%; border-collapse: collapse; margin: 20px 0; font-size: 12px; }
          th, td { border: 1px solid black; padding: 8px; }
          .numCell { text-align: right; }
        </style>
      </head>
      <body>
        <h4 style="text-align:center">${this.$t('label.reports_title')}</h4>
              <h4 style="text-align:center">
                ${this.$t('label.report1_title')} (${this.$t('label.as_on')} ${this.selected_date.toLocaleDateString()})
              </h4>
              <table>
                <thead>${tableHeaders}</thead>
                <tbody>${tableRows}</tbody>
        </table>
      </body>
    </html>`
        const PrintWin = window.open('', '', 'width=800,height=600');
        PrintWin.document.write(prtHtml);
        PrintWin.document.close();
        this.showSpinner = false
        PrintWin.focus();
        PrintWin.print();
        PrintWin.close();
      },

      onRegionChange(region_id, region_desc) {
        this.region_desc = region_desc
        this.reloadData()
      },
      showSections(item) {
        const hist = {
          selected_region: item.region_id,
          selected_deu: item.deu_id,
          perPage: 20,
          currentPage: 1
        }
        this.$store.dispatch('SAVE_HISTORY', { data: hist, for: 'sections' })
        this.$store.dispatch('SET_USE_HISTORY')
        this.$router.push('/inventory_data/sections')
      },
    },

    computed: {
      report() {
        return this.customSort(this.$store.state.Reports.report_1_data, 'deu_description')
      },
      total_deu() {
        return this.report.length
      },
      total_length_km_road_class_m() {
        return numFormat(this.report.reduce((acc, currVal) => acc + currVal.length_km_road_class_m, 0,))
      },
      total_length_km_road_class_em() {
        return numFormat(this.report.reduce((acc, currVal) => acc + currVal.length_km_road_class_em, 0,))
      },
      total_length_km_road_class_zh() {
        return numFormat(this.report.reduce((acc, currVal) => acc + currVal.length_km_road_class_zh, 0,))
      },
      total_road_class_none() {
        return numFormat(this.report.reduce((acc, currVal) => acc + currVal.road_class_none, 0,))
      },
    },

    watch: {
      selected_date(value) {
        if (!value) this.selected_date = new Date()
        else this.reloadData()
      },
    }
  }
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

::v-deep .md-table-head-label {
  overflow: visible !important;
  white-space: normal !important;
  text-align: center !important;
  word-wrap: break-word !important;
}

.md-table-cell.md-numeric {
  padding: 0px !important;
  padding-right: 18px !important;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}

thead {
  display: table-header-group;
}

tfoot {
  display: table-footer-group;
}
</style>